import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataInitialOrder,
  searchOrden,
  editedOrden,
  cancelOrden,
  generarNotaCredito,
} from "actions/transportOrdersAction";
import { getListParentCities } from "actions/admin/parentCityAction";
import { getListOfficeAll } from "actions/admin/officeAction";
import { getFechaFormat } from "utils/formatFecha";
import { validarRut } from "utils/rut";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const useEditTransportOrder = () => {
  const dispatch = useDispatch();
  const [validateForm, setValidateForm] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [titleModulo, setTitleModulo] = useState("");
  const [infoData, setInfoData] = useState({});
  const [hidden, setHidden] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [nameModulo, setNameModulo] = useState("");
  const [showIsModal, setShowIsModal] = useState(false);
  const [showModalPrint, setShowModalPrint] = useState(false);
  const [textSearch, setTextSearch] = useState(
    "Consultando la orden de flete..."
  );

  const [updateData, setUpdateData] = useState(false);
  const [statusValidate, setStatusValidate] = useState(validateForm);
  const [isLoadingInitial, setIsLoadingInitial] = useState(false);
  const [bloquear, setBloquear] = useState(false);
  const [listPackages, setListPackages] = useState([]);
  const [loadingPago, setLoadingPago] = useState(false);
  const [totalesTransportOrder, setTotalesTransportOrder] = useState({
    total_bultos: 0,
    total_kilos: 0,
    total_metro_cubico: 0,
    subtotal_orden: 0,
    valor_neto: 0,
    valor_iva: 0,
    total: 0,
  });

  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    setValue,
    control,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const forma_pago_id_watch = watch("forma_pago_id");
  const numero_documento_watch = watch("numero_documento");

  const { disabledBtnCrear, disabledBtnAnular, disabledImprimir } = useSelector(
    (state) => state.orders
  );
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { listOffices } = useSelector((state) => state.offices);
  const { configuracion } = useSelector((state) => state.config);
  const { listParentCities } = useSelector((state) => state.parentCities);

  const {
    documentacionsCarga,
    documentacionsPago,
    documentacionsPagoSinB1,
    tipoEnvios,
    formaPagos,
    dataTransportOrder,
    dataTransportOrderLast,
    initialOrden,
    visibleCarga,
    visibleDownload,
  } = useSelector((state) => state.transportOrders);

  const getDatInitial = async (numero, isVisibleLoading = true) => {
    try {
      setTextSearch("Consultando la orden de flete...");
      if (isVisibleLoading) {
        setIsLoadingInitial(true);
      }
      await dispatch(getDataInitialOrder());
      await dispatch(getListOfficeAll("active"));

      let result = await dispatch(searchOrden(numero));

      const data = result?.data;
      if (data !== null) {
        reset({ ...data });
        setListPackages(data?.cargas ?? []);
        setTotalesTransportOrder({
          total_bultos: data?.total_bultos ?? 0,
          total_kilos: data?.total_kilos ?? 0,
          total_metro_cubico: data?.total_metro_cubico ?? 0,
          subtotal_orden: data?.subtotal_orden ?? 0,
          valor_neto: data?.valor_neto ?? 0,
          valor_iva: data?.valor_iva ?? 0,
          total: data?.total ?? 0,
        });

        if (data?.estado_orden === "Pendiente tarifario") {
          handleblockPaidInput(null, "no_price");
        }

        setValue("rut_factura", data?.rut_factura);
        setValue("razon_social_factura", data?.razon_social_factura);
        handleTipoDoc();
        //setBloquear(true);
        if (isVisibleLoading) {
          setIsLoadingInitial(false);
        }

        if (
          typeof data?.fecha_orden !== "undefined" &&
          data?.fecha_orden !== ""
        ) {
          setValue("fecha_orden", data?.fecha_orden);
        }
        //setBloquear(true);
        let validReceive =
          data?.cliente_rut === data?.rut_remitente
            ? "remitente"
            : "destinatario";
        setValue("tipo_cliente", validReceive);

        /**if (
          (parseInt(data?.forma_pago_id) === 2 ||
            parseInt(data?.forma_pago_id) === 1) &&
          data?.documentacion_id_pago === ""
        ) {
          setBloquear(false);
        }**/

        /**if (document.querySelector("#forma_pago_id")) {
          document.querySelector("#forma_pago_id").disabled = true;
        }**/

        /**if (document.querySelector("#documentacion_id_pago")) {
          setBloquear(true);
          // document.querySelector("#documentacion_id_pago").disabled = true;
        }**/

        /**if (
          data?.nota_creditos?.length > 0 &&
          data?.numero_documento === "" &&
          data?.estado_orden !== "Nulas"
        ) {
          document.querySelector("#documentacion_id_pago").disabled = false;
        }**/

        /**if (
          Number(data?.forma_pago_id) === 2 &&
          data?.documentacion_id_pago === ""
        ) {
          setBloquear(false);
          // document.querySelector("#documentacion_id_pago").disabled = false;
        }**/
      }

      /**let response = await dispatch(searchOrden({ numero_orden: numero }));
      if (response.status) {
        let telefono = "";

        if (companyDataBasic?.country?.code) {
          if (
            dataOrders?.telefono_avisas?.startsWith(
              companyDataBasic?.country?.code
            )
          ) {
            telefono = dataOrders.telefono_avisas.slice(
              companyDataBasic?.country?.code?.length
            );
          } else {
            telefono = dataOrders.telefono_avisas;
          }
        }

        dataOrders.telefono_avisas = telefono;

        //reset({ defaultValues: response?.data })
        setValue("numero_orden", numero);
        setValue("forma_pago_id", response?.data?.forma_pago_id);
        setValue("documentacion_id", response?.data?.documentacion_id);
        setValue("sucursal_origen_id", response?.data?.sucursal_origen_id);
        setValue("oficina_origen_id", response?.data?.oficina_origen_id);
        setValue("sucursal_destino_id", response?.data?.sucursal_destino_id);
        setValue("oficina_destino_id", response?.data?.oficina_destino_id);
        setValue("tipo_envio_id", response?.data?.tipo_envio_id);
        setValue("cliente_rut", response?.data?.cliente_rut);
        setValue("rut_remitente", response?.data?.rut_remitente);
        setValue("rut_destinatario", response?.data?.rut_destinatario);
        setValue("telefono_avisas", telefono);

        dispatch(resultTotales(response?.data?.descuento));
        //setInitial(true)
        if (response?.data?.tipo_cliente === "remitente") {
          setValue("tipo_cliente", "remitente");
        } else {
          setValue("tipo_cliente", "destinatario");
        }
        if (response?.data?.codigo_interno === "si") {
          setValue("codigo_interno", "si");
          if (document.getElementById("codigo_interno_nro")) {
            document.getElementById("codigo_interno_nro").readOnly = false;
          }
        } else {
          setValue("codigo_interno", "no");
          if (document.getElementById("codigo_interno_nro")) {
            document.getElementById("codigo_interno_nro").readOnly = true;
          }
        }

        if (getValues("rut_remitente") === RUT_MESON) {
          if (document.getElementById("razon_social_remitente")) {
            document.getElementById("razon_social_remitente").readOnly = false;
          }
        }
        if (getValues("rut_destinatario") === RUT_MESON) {
          if (document.getElementById("razon_social_destinatario")) {
            document.getElementById(
              "razon_social_destinatario"
            ).readOnly = false;
          }
        }

        if (
          typeof response?.data?.fecha_orden !== "undefined" &&
          response?.data?.fecha_orden !== ""
        ) {
          let fecha = Date.parse(response?.data?.fecha_orden);

          setValue("fecha_orden", new Date(fecha));
        }

        if (
          typeof dataOrders?.messageSuccess !== "undefined" &&
          dataOrders?.messageSuccess?.status === true
        ) {
          toast.success(dataOrders?.messageSuccess?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        if (response?.data?.estado_orden === "Pendiente tarifario") {
          handleblockPaidInput(null, "no_price");
        }

        if (document.querySelector("#generar-documento")) {
          document.querySelector("#documentacion_id_pago").disabled = false;
        }

        
      }**/
    } catch (err) {
      toast.error("Hubo un error al obtener los datos de la orden", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoadingInitial(false);
    }
  };

  const getListParent = async () => {
    if (companyDataBasic !== null) {
      await dispatch(
        getListParentCities(companyDataBasic?.country?.id, "active")
      );
    }
  };

  function esTimestamp(valor) {
    // Verifica que sea un número
    if (typeof valor !== "number") {
      return false;
    }

    // Comprueba que esté dentro del rango de timestamps válidos
    const minTimestamp = 0;
    const maxTimestamp = 8640000000000000; // Aproximadamente el año 275760
    return valor >= minTimestamp && valor <= maxTimestamp;
  }

  const getDataUpdate = (data) => {
    let isValid = validarRut(data?.cliente_rut);
    if (!isValid.status) {
      toast.error(`${isValid.message} del cliente`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    isValid = validarRut(data?.rut_remitente);
    if (!isValid.status) {
      toast.error(`${isValid.message} del remitente`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    isValid = validarRut(data?.rut_destinatario);
    if (!isValid.status) {
      toast.error(`${isValid.message} del destinatario`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    /**if (listPackages?.length === 0) {
      toast.error("Mínimo 1 detalle de la carga", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    if (typeof data?.cargas === "undefined" || data?.cargas?.length === 0) {
      toast.error("Mínimo 1 detalle de la carga", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }**/

    let pago_descripcion = document.getElementById("forma_pago_id");
    const cityOrigin = listOffices?.find(
      (item) =>
        item.id === Number(document.querySelector("#city_origin_id").value)
    );
    let parent_offices =
      cityOrigin && cityOrigin.parent_office ? [cityOrigin.parent_office] : [];
    const officeOrigin = parent_offices?.find(
      (item) =>
        item.id === Number(document.querySelector("#office_origin_id").value)
    );
    let fechaUpdate = getValues("fecha_orden");

    if (esTimestamp(getValues("fecha_orden"))) {
      fechaUpdate = new Date(getValues("fecha_orden"));
    }
    const information = {
      ...data,
      fecha_orden: getFechaFormat(fechaUpdate),
      city_origin_id: document.querySelector("#city_origin_id").value,
      office_origin_id: document.querySelector("#office_origin_id").value,
      city_destination_id: document.querySelector("#city_destination_id").value,
      office_destination_id: document.querySelector("#office_destination_id")
        .value,
      forma_pago_id: getValues("forma_pago_id"),
      documentacion_id_pago: getValues("documentacion_id_pago"),
      numero_documento: getValues("numero_documento"),
      forma_pago_descripcion:
        pago_descripcion.options[pago_descripcion.selectedIndex].text,
      numero_orden: data.order_number.toString().replaceAll(" ", "").trim(),
      telefono_avisas: `${companyDataBasic?.country?.code}${data?.telefono_avisas}`,
      city_origin_name: cityOrigin?.name,
      office_origin_name: officeOrigin?.name,
      cargas: listPackages,
    };

    return information;
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (data?.type_price === "with_price") {
        if (
          parseInt(data?.forma_pago_id) === 2 &&
          data?.documentacion_id_pago === ""
        ) {
          toast.error("Seleccione el tipo de documento de pago", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          return false;
        }
      }
      const information = getDataUpdate(data);

      if (information.office_origin_id?.length === 0) {
        toast.error("Debe seleccionar la comuna de origen", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      if (information.office_destination_id?.length === 0) {
        toast.error("Debe seleccionar la comuna de origen", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      if (listPackages?.length === 0) {
        toast.error("Mínimo 1 detalle de la carga", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      if (typeof data?.cargas === "undefined" || data?.cargas?.length === 0) {
        toast.error("Mínimo 1 detalle de la carga", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      if (
        Number(data?.forma_pago_id) !==
          Number(dataTransportOrderLast?.forma_pago_id) &&
        data?.numero_documento !== "B-1" &&
        data?.numero_documento !== ""
      ) {
        toast.error(
          "Estás cambiando la forma de pago, ya tiene un documento, debe generar una nota de crédito",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoading(false);
        return false;
      }

      setTitleModulo("FACTURA A NOMBRE DE:");

      //Caso de editar o agregar, verificando la edición
      const response = switchEdit(information);

      if (response.status === 1) {
        setInfoData({ ...data, cliente_cod: dataTransportOrder.cliente_cod });
        Swal.fire({
          title: response.message,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
        });
        setLoading(false);
      } else if (response.status === 2) {
        Swal.fire({
          title: response.message,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await editOrdenAction(information);
          }
        });
        setLoading(false);
      } else if (response.status === 0) {
        if (
          (parseInt(information?.forma_pago_id) === 2 ||
            parseInt(information?.forma_pago_id) === 1) &&
          parseInt(information?.documentacion_id_pago) === 1 &&
          companyDataBasic?.invoice &&
          information.numero_documento === ""
        ) {
          setInfoData((infoData) => information);
          setHidden(true);
          setIsVisible(true);
          setNameModulo("orden");
        } else {
          await editOrdenAction(information);
        }
      } else if (response.status === 3) {
        Swal.fire({
          title: response.message,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
        });
        setLoading(false);
      }

      setLoading(false);
    } catch (err) {
      toast.error("Hubo un error al editar la orden de flete", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const switchEdit = (data) => {
    if (dataTransportOrder?.estado_orden === "Pendiente tarifario") {
      return {
        status: 3,
        message:
          "La orden se encuentra Pendiente tarifario, debe dirigirse con el administrador",
      };
    }

    if (companyDataBasic?.invoice) {
      if (dataTransportOrderLast.codigo_interno === "si") {
        if (
          dataTransportOrderLast.codigo_interno_nro !==
            parseInt(data.codigo_interno_nro) &&
          dataTransportOrderLast.numero_documento !== ""
        ) {
          //getDatInitial(getValues("numero_orden"), false);
          return {
            status: 1,
            message:
              "Estás cambiando el código interno del cliente y ya fue facturada la orden, debe generar una nota de crédito",
          };
        }
      }

      if (parseInt(dataTransportOrderLast.total) !== parseInt(data.total)) {
        if (
          dataTransportOrderLast.numero_documento !== undefined &&
          dataTransportOrderLast.numero_documento.length > 0 &&
          dataTransportOrderLast.numero_documento !== "B-1"
        ) {
          //getDatInitial(getValues("numero_orden"), false);
          return {
            status: 1,
            message:
              "Estás cambiando el monto y ya fue facturada la orden, debe generar una nota de crédito",
          };
        }

        if (dataTransportOrderLast.rendicion === true) {
          return {
            status: 2,
            message:
              "La orden está relacionada con una rendición de caja, ¿Seguro desea modificar el monto?",
          };
        }
      }

      if (dataTransportOrderLast.fecha_orden_format !== data.fecha_orden) {
        if (dataTransportOrderLast.rendicion === true) {
          return {
            status: 2,
            message:
              "La orden está relacionada con una rendición de caja, ¿Seguro desea modificar la fecha?",
          };
        }
      }
    }

    return { status: 0, message: "" };
  };

  const editOrdenAction = async (information) => {
    const ordenId = getValues("order_number");
    let dataReceiveOrden = { ...information };

    let sucursal_origen = document.querySelector("#sucursal_origen_id");

    let dataInvoice = { rut: dataReceiveOrden?.rut_remitente };

    if (dataReceiveOrden?.factura) {
      dataInvoice = {
        rut: dataReceiveOrden?.factura?.rut_factura ?? "",
        full_name: dataReceiveOrden?.factura?.razon_social_factura ?? "",
        address: dataReceiveOrden?.factura?.direccion_factura ?? "",
        phone_number: dataReceiveOrden?.factura?.contacto_factura ?? "",
        email: dataReceiveOrden?.factura?.email_factura ?? "",
        tax_draft: dataReceiveOrden?.factura?.giro_factura ?? "",
        city: dataReceiveOrden?.factura?.city_origin_name ?? "",
        office: dataReceiveOrden?.factura?.office_origin_name ?? "",
      };
      delete dataReceiveOrden?.factura;
    }

    let dataOrden = {
      ...dataReceiveOrden,
      factura: dataInvoice,
      sucursal_name:
        sucursal_origen?.options[sucursal_origen.selectedIndex]?.text,
    };

    const response = await dispatch(editedOrden(ordenId, dataOrden));
    setLoading(false);
    if (response.status) {
      //setInitial(false)
      if (!showIsModal) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      }

      setInfoData((infoData) => null);
      setHidden(false);
      setIsVisible(false);
      setNameModulo("orden");

      setTextSearch("Actualizando la información de la orden de flete...");
      //await dispatch(searchOrden(ordenId));
      await getDatInitial(ordenId);
      // setInitial(true)
      return true;
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const handleblockPaidInput = (event, valor) => {
    const value = event !== null ? event.target.value : valor;
    let checked = value === "no_price" ? true : false;
    if (value === "no_price") {
      setValue("documentacion_id_pago", "");
      setValue("forma_pagodescuento_id", "No");
      setValue("total_carga", "No");
      setValidateForm(false);
      //setStatusValidate(statusValidate => false)
      setBloquear(true);
    } else {
      //setValidateForm(true)
    }
    if (document.querySelector("#documentacion_id_pago")) {
      document.querySelector("#documentacion_id_pago").disabled = checked;
      document.querySelector("#descuento").disabled = checked;
      document.querySelector("#total_carga").readOnly = checked;
    }
  };

  const handleShow = () => {
    setIsVisible(false);

    if (dataTransportOrder?.numero_documento) {
      setIsVisible(true);
    }

    setInfoData((infoData) => dataTransportOrder);
    setNameModulo("anular");
    setHidden(true);
    setTitleModulo("ANULAR ORDEN");
    setUpdateData((updateData) => !updateData);
  };

  const anularOrden = async (information, e) => {
    let response;

    if (nameModulo === "anular") {
      response = await dispatch(cancelOrden(information));
    } else {
      response = await dispatch(
        generarNotaCredito({
          ...information,
          numero_documento: "",
        })
      );
      setValue("numero_documento", "");
      setValue("rut_factura", "");
      setValue("razon_social_factura", "");
      setValue("documentacion_id_pago", "");
    }

    if (response.status) {
      if (nameModulo === "nota") {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        //const data = getDataUpdate(infoData);
        //await getDatInitial(information?.order_number, false);
        setIsVisible(false);
        setInfoData((infoData) => null);
        setNameModulo("");
        setHidden(false);
        setTextSearch("Actualizando la información de la orden de flete...");
        const forma_pago_id = document.querySelector("#forma_pago_id").value;
        setValue("forma_pago_id", "-");
        setValue("forma_pago_id", forma_pago_id);
        /**if (parseInt(forma_pago_id) === 2) {
          setBloquear(false);
        } else {
          setBloquear(true);
        }**/
        //setValue("numero_documento", "");
        //await editOrdenAction(data);**/
        /**let evt = new CustomEvent("submit", {
          bubbles: true,
          cancelable: true,
        });

        let standardEvt = new Event("build");

        document
          .getElementById("frmOrders")
          .addEventListener("build", function () {
            // Código que se ejecutará después de que se complete el evento
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        document.getElementById("frmOrders").dispatchEvent(evt);
        // Disparar el evento estándar
        document.getElementById("frmOrders").dispatchEvent(standardEvt);**/
      } else if (nameModulo === "anular") {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        setIsVisible(false);

        setInfoData((infoData) => null);
        setNameModulo("");
        setHidden(false);
        setTitleModulo("ANULAR ORDEN");
        setUpdateData((updateData) => !updateData);
        setTextSearch("Actualizando la información de la orden de flete...");
        await getDatInitial(getValues("order_number"), false);
      }
      return true;
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const handleNotaCredito = () => {
    setIsVisible(true);
    const info = {
      ...dataTransportOrder,
      codigo_interno_nro: getValues("codigo_interno_nro"),
      rut_cliente_nuevo: getValues("cliente_rut"),
      total: getValues("total"),
    };

    setInfoData({ ...info });
    setNameModulo("nota");
    setHidden(true);
    setTitleModulo("NOTA DE CREDITO A NOMBRE DE:");
    setUpdateData((updateData) => !updateData);
  };

  const handleCloseModal = () => {
    setShowIsModal(!showIsModal);
    //getDatInitial(getValues("order_number"), false);
  };

  const handleGenerateModalNota = () => {
    setShowIsModal(!showIsModal);
    //getDatInitial(getValues("numero_orden"), false);
  };

  const handleCloseModalPayment = () => {
    setOpenForm(false);
  };

  const handleClosePrint = () => {
    //event.preventDefault()
    setShowModalPrint(!showModalPrint);
  };

  const handleTipoDoc = (event) => {
    let pago_descripcion = document.getElementById("documentacion_id");

    if (pago_descripcion) {
      pago_descripcion =
        pago_descripcion.options[pago_descripcion.selectedIndex].text;

      if (pago_descripcion.toLowerCase() === "sin documento") {
        setStatusValidate((statusValidate) => false);
        setValue("numero_documento_carga", "");
      } else {
        setStatusValidate((statusValidate) => true);
      }
    }
  };

  (function () {
    if (typeof window.CustomEvent === "function") return false;

    function CustomEvent(event, params) {
      params = params || { bubbles: true, cancelable: true, detail: undefined };
      var evt = document.createEvent("submit");
      evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
      );
      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
  })();

  const handleOrderNumberBlur = (event) => {
    const nro_order = event.target.value.trim().replaceAll(" ", "");
    setValue("order_number", nro_order);
  };

  const formElement = {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    setValue,
    control,
    onSubmit,
    forma_pago_id_watch,
    numero_documento_watch,
  };

  const state = {
    validateForm,
    isLoading,
    titleModulo,
    infoData,
    hidden,
    isVisible,
    initialOrden,
    disabledBtnCrear,
    openForm,
    nameModulo,
    disabledBtnAnular,
    disabledImprimir,
    showModalPrint,
    updateData,
    showIsModal,
    statusValidate,
    visibleCarga,
    isLoadingInitial,
    bloquear,
    companyDataBasic,
    listOffices,
    documentacionsCarga,
    documentacionsPago,
    documentacionsPagoSinB1,
    tipoEnvios,
    dataTransportOrder,
    formaPagos,
    listPackages,
    loadingPago,
    totalesTransportOrder,
    visibleDownload,
    configuracion,
    listParentCities,
    textSearch,
  };

  const actions = {
    handleblockPaidInput,
    setLoading,
    setHidden,
    editOrdenAction,
    getDatInitial,
    handleCloseModalPayment,
    setOpenForm,
    handleCloseModal,
    handleNotaCredito,
    anularOrden,
    handleClosePrint,
    setShowIsModal,
    handleShow,
    handleTipoDoc,
    handleGenerateModalNota,
    handleOrderNumberBlur,
    setListPackages,
    setLoadingPago,
    setTotalesTransportOrder,
    setBloquear,
    getListParent,
  };

  return {
    actions,
    formElement,
    state,
  };
};
