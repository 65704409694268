import React, { Fragment, useEffect, useState } from "react";
import { Form, Col, Row, InputGroup, Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useDetailPackage } from "hooks/Orders/useDetailPackage";
import TitlePrincipal from "../Layouts/TitlePrincipal";
import { downloadDocumento } from "utils/downloadFile";
import download from "assets/images/download_small.png";
import { searchDocument } from "actions/facturacionAction";
//import { useDocumentBoleta } from "hooks/MetodoPago/useDocumentBoleta";
import { NumericFormat } from "react-number-format";
import {
  validateOnlyNumber,
  handlePasteOnlyNumber,
} from "utils/validationInput";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Pagos = ({
  formElement,
  stateOrden,
  actionsOrden,
  isGenerateDocument,
  editOrden,
  getDatInitial,
}) => {
  const dispatch = useDispatch();
  const { register, errors, getValues, setValue, forma_pago_id_watch } =
    formElement;

  const { resultTotales } = useDetailPackage();

  const {
    listPackages,
    validateForm,
    bloquear,
    companyDataBasic,
    //loadingPago,
    totalesTransportOrder,
    formaPagos,
    documentacionsPago,
    visibleDownload,
    dataTransportOrder,
  } = stateOrden;
  const { setTotalesTransportOrder, setBloquear } = actionsOrden;
  //const { setTotalesTransportOrder, setOpenForm, setBloquear, setLoadingPago } =
  //actionsOrden;

  const { isBloqueCta } = useSelector((state) => state.orders);

  const [descargar, setDescargar] = useState(false);
  const [isDisabledNroDocumento, setIsDisabledNroDocumento] = useState(false);

  /**const { generateDocumento } = useDocumentBoleta(
    loadingPago,
    setLoadingPago,
    getDatInitial
  );**/

  const applyDiscount = (event) => {
    const totalesGlobal = resultTotales(event.target.value, listPackages);
    setTotalesTransportOrder(totalesGlobal);
  };

  useEffect(() => {
    if (Object.keys(totalesTransportOrder).length > 0) {
      setValue("total_bultos", totalesTransportOrder.total_bultos);
      setValue("total_kilos", totalesTransportOrder.total_kilos);
      setValue("total_metro_cubico", totalesTransportOrder.total_metro_cubico);
      setValue("subtotal_orden", totalesTransportOrder.subtotal_orden);
      setValue("valor_neto", totalesTransportOrder.valor_neto);
      setValue("valor_iva", totalesTransportOrder.valor_iva);
      setValue("total", totalesTransportOrder.total);

      setValue("rut_factura", dataTransportOrder?.rut_factura ?? "");
      setValue(
        "razon_social_factura",
        dataTransportOrder?.razon_social_factura ?? ""
      );
      //setBloquear(true);
    }
    setValue("documentacion_id_pago", getValues("documentacion_id_pago"));
    setIsDisabledNroDocumento(true);
    if (
      getValues("numero_documento") !== "" &&
      getValues("documentacion_id_pago") !== 8 &&
      !companyDataBasic?.invoice
    ) {
      setIsDisabledNroDocumento(companyDataBasic?.invoice);
    }
    setValue("numero_documento", getValues("numero_documento") ?? "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalesTransportOrder]);

  useEffect(() => {
    if (dataTransportOrder !== null) {
      setValue("rut_factura", dataTransportOrder?.rut_factura ?? "");
      setValue(
        "razon_social_factura",
        dataTransportOrder?.razon_social_factura ?? ""
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTransportOrder?.rut_factura]);

  useEffect(() => {
    if (isBloqueCta) {
      setValue("forma_pago_id", 3);
      //setBloquear(true);
    } else {
      if (parseInt(dataTransportOrder?.forma_pago_id) === 3) {
        setValue("forma_pago_id", "");
      }
      //setBloquear(isBloqueCta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBloqueCta]);

  const getValidStatuFormPaymentId = () => {
    if (forma_pago_id_watch === "") {
      setBloquear(true);
      setValue("documentacion_id_pago", "");
      setValue("numero_documento", "");
      setIsDisabledNroDocumento(true);
    } else if (
      Number(forma_pago_id_watch) === 1 ||
      Number(forma_pago_id_watch) === 2
    ) {
      if (
        dataTransportOrder?.nota_creditos?.length > 0 &&
        dataTransportOrder?.numero_documento?.length === 0
      ) {
        setBloquear(false);

        setValue("documentacion_id_pago", "");
        setValue("numero_documento", "");
      } else {
        setBloquear(true);
      }
    }
  };

  useEffect(() => {
    getValidStatuFormPaymentId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forma_pago_id_watch]);

  const cambiarFormaPago = (event) => {
    if (
      parseInt(event.target.value) === 1 ||
      parseInt(event.target.value) === 3
    ) {
      if (
        dataTransportOrder !== null &&
        Number(dataTransportOrder.forma_pago_id) !==
          Number(event.target.value) &&
        dataTransportOrder?.numero_documento !== "" &&
        dataTransportOrder?.numero_documento !== "B-1"
      ) {
        setValue("forma_pago_id", Number(dataTransportOrder.forma_pago_id));
        Swal.fire({
          title:
            "Estás cambiando la forma de pago, ya tiene un documento, debe generar una nota de crédito",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
        });
        return false;
      } else {
        setBloquear(true);
        setValue("documentacion_id_pago", "");
        setValue("numero_documento", "");
        setIsDisabledNroDocumento(true);
      }
    }

    if (parseInt(event.target.value) === 3) {
      if (
        getValues("codigo_interno") === "no" ||
        parseInt(getValues("codigo_interno_nro")) === 111
      ) {
        toast.error("El cliente no es cuenta correntista", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setValue("forma_pago_id", "");
        return false;
      }
    }
    if (parseInt(event.target.value) === 2) {
      if (getValues("type_price") === "no_price") {
        toast.error("La orden es sin precio, seleccione otra forma de pago", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setValue("forma_pago_id", "");
        return false;
      } else {
        if (
          getValues("codigo_interno") === "si" ||
          getValues("codigo_interno_nro") !== ""
        ) {
          toast.error(
            "El cliente tiene un código interno debe seleccionar  la forma de pago: Por pagar o Cuenta corriente",
            { position: toast.POSITION.TOP_RIGHT }
          );
          setValue("forma_pago_id", "");
          return false;
        } else {
          setBloquear((bloquear) => false);
        }
      }
    }
  };

  const downloadDoc = async (payment) => {
    setDescargar(true);

    if (payment?.assignable_url.length === 0) {
      const tipo =
        parseInt(getValues("documentacion_id_pago")) === 1
          ? "factura"
          : "boleta";
      const body = {
        tipo_documento: tipo,
        numero_documento: payment?.document_number,
      };
      await dispatch(searchDocument(body));
    } else {
      let data = {
        url: payment?.assignable_url,
        numero_documento: payment?.document_number,
      };
      downloadDocumento(data, "_blank");
    }
    setDescargar(false);
  };

  const checkBoleta1 = (event) => {
    setIsDisabledNroDocumento(false);
    if (parseInt(event.target.value) === 8) {
      document.getElementById("numero_documento").value = "B-1";
      if (document.querySelector("#generar-documento")) {
        document.querySelector("#generar-documento").style.display = "none";
      }
      setIsDisabledNroDocumento(true);
    } else {
      if (document.getElementById("documentacion_id_pago").value === 8) {
        document.getElementById("numero_documento").value = "";
      } else {
        setIsDisabledNroDocumento(companyDataBasic?.invoice);
        document.getElementById("numero_documento").value = "";
      }

      if (document.querySelector("#generar-documento")) {
        document.querySelector("#generar-documento").style.display = "block";
      }
    }
  };

  /**const validateDocument = (event) => {
    event.preventDefault();
    const data = getValues();

    if (data.documentacion_id_pago === "") {
      toast.error("Seleccione el documento de pago", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (parseInt(data.documentacion_id_pago) === 2) {
      generateDocumento(event, data, setValue);
    } else {
      setOpenForm(true);
    }
  };

  const validateButton = () => {
    if (
      dataTransportOrder?.nota_creditos?.length > 0 &&
      dataTransportOrder?.numero_documento === "" &&
      dataTransportOrder?.estado_orden !== "Nulas"
    ) {
      return true;
    }

    if (
      Number(dataTransportOrder?.forma_pago_id) === 2 &&
      dataTransportOrder?.documentacion_id_pago === ""
    ) {
      return true;
    }
    return false;
  };**/

  return (
    <Fragment>
      <Row className="mt-1 mb-1">
        <Col md={6} sm={12} className="pr-4">
          <TitlePrincipal title="TOTALES" classTitle="bg-gray-wran" />
          <Row className="bg-soft-blue">
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="total_bultos"
                className="pr-0 mb-1"
              >
                <Form.Label>Bulto</Form.Label>
                <Form.Control
                  type="text"
                  name="total_bultos"
                  defaultValue={totalesTransportOrder?.total_bultos}
                  ref={register}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="total_kilos"
                className="pr-0 pl-0 mb-1"
              >
                <Form.Label>Kilo</Form.Label>
                <Form.Control
                  type="hidden"
                  name="total_kilos"
                  defaultValue={totalesTransportOrder?.total_kilos}
                  ref={register}
                  readOnly
                />
                <NumericFormat
                  displayType="text"
                  className="form-control text-padding"
                  decimalScale={0}
                  fixedDecimalScale={false}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                  value={totalesTransportOrder?.total_kilos}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="total_metro_cubico"
                className="pr-0 pl-0 mb-1"
              >
                <Form.Label>M3</Form.Label>
                <Form.Control
                  type="hidden"
                  name="total_metro_cubico"
                  defaultValue={totalesTransportOrder?.total_metro_cubico}
                  ref={register}
                  readOnly
                />
                <NumericFormat
                  displayType="text"
                  className="form-control text-padding"
                  decimalScale={0}
                  fixedDecimalScale={false}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                  value={totalesTransportOrder?.total_metro_cubico}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="subtotal_orden"
                className="pr-0 pl-0 mb-1"
              >
                <Form.Label>Subtotal</Form.Label>
                <Form.Control
                  type="hidden"
                  name="subtotal_orden"
                  defaultValue={totalesTransportOrder?.subtotal_orden}
                  ref={register}
                  readOnly
                />
                <NumericFormat
                  displayType="text"
                  className="form-control text-padding"
                  decimalScale={0}
                  fixedDecimalScale={false}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                  value={totalesTransportOrder?.subtotal_orden}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="descuento"
                className="pl-0 pr-0 mb-1"
              >
                <Form.Label>Dsct 10%</Form.Label>
                <Form.Control
                  as="select"
                  name="descuento"
                  onChange={(event) => applyDiscount(event)}
                  ref={register}
                >
                  <option value="No">No</option>
                  <option value="Si">Si</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="bg-soft-blue ">
            <Col lg md={4} sm={4}>
              <Form.Group as={Col} controlId="valor_neto" className="pr-0">
                <Form.Label>Neto</Form.Label>
                <Form.Control
                  type="hidden"
                  name="valor_neto"
                  defaultValue={totalesTransportOrder?.valor_neto}
                  ref={register}
                  readOnly
                />
                <NumericFormat
                  displayType="text"
                  className="form-control text-padding"
                  decimalScale={0}
                  fixedDecimalScale={false}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                  value={totalesTransportOrder?.valor_neto}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group as={Col} controlId="valor_iva" className="pr-0 pl-0">
                <Form.Label>IVA $</Form.Label>
                <Form.Control
                  type="hidden"
                  name="valor_iva"
                  defaultValue={totalesTransportOrder?.valor_iva}
                  ref={register}
                  readOnly
                />
                <NumericFormat
                  displayType="text"
                  className="form-control text-padding"
                  decimalScale={0}
                  fixedDecimalScale={false}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                  value={totalesTransportOrder?.valor_iva}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group as={Col} controlId="total" className="pl-0 pr-0">
                <Form.Label>Bruto $</Form.Label>
                <Form.Control
                  type="hidden"
                  name="total"
                  defaultValue={totalesTransportOrder?.total}
                  ref={register}
                  readOnly
                />
                <NumericFormat
                  displayType="text"
                  className="form-control text-padding"
                  decimalScale={0}
                  fixedDecimalScale={false}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                  value={totalesTransportOrder?.total}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <TitlePrincipal title="DOCUMENTO DE FORMA DE PAGO" />
          <Row className="bg-soft-blue">
            <Col lg md={6} sm={6}>
              <Form.Group
                as={Col}
                controlId="forma_pago_id"
                className="pr-0 pl-0 mb-1"
              >
                <Form.Label>Forma de Pago</Form.Label>
                <Form.Control
                  as="select"
                  name="forma_pago_id"
                  ref={register({
                    required: { value: validateForm, message: "Requerido" },
                  })}
                  onChange={(event) => cambiarFormaPago(event)}
                  disabled={isBloqueCta}
                >
                  <option value="">Seleccione</option>
                  {formaPagos.length > 0 &&
                    formaPagos.map((forma) => (
                      <option key={forma.id} value={forma.id}>
                        {forma.description}
                      </option>
                    ))}
                </Form.Control>
                {errors.forma_pago_id && (
                  <Form.Text className="text-danger">
                    {errors?.forma_pago_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg md={6} sm={6}>
              <Form.Group
                as={Col}
                controlId="documentacion_id_pago"
                className="pr-0 pl-0 mb-1"
              >
                <Form.Label>Documento</Form.Label>
                <Form.Control
                  as="select"
                  name="documentacion_id_pago"
                  ref={register}
                  disabled={bloquear}
                  onChange={(event) => checkBoleta1(event)}
                >
                  <option value="">Seleccione</option>
                  {documentacionsPago.length > 0 &&
                    documentacionsPago.map((documents) => (
                      <option key={documents.id} value={documents.id}>
                        {documents.description}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md sm={12}>
              <Form.Group
                as={Col}
                controlId="numero_documento"
                className="pr-0 pl-0 mb-1"
              >
                <Form.Label>N° Documento</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="numero_documento"
                    defaultValue={getValues("numero_documento")}
                    ref={register({
                      required: {
                        value: !isDisabledNroDocumento,
                        message: "Requerido",
                      },
                    })}
                    readOnly={isDisabledNroDocumento}
                    maxLength={25}
                    onKeyPress={(event) => validateOnlyNumber(event)}
                    onPaste={(event) => handlePasteOnlyNumber(event)}
                  />
                  {visibleDownload && (
                    <InputGroup.Append>
                      <Button
                        variant="warning"
                        type="button"
                        onClick={() =>
                          downloadDoc(
                            dataTransportOrder?.documentation_payments
                          )
                        }
                        disabled={descargar}
                        className="btn-sm"
                      >
                        <img
                          src={download}
                          alt="Descargar documento"
                          className="btn-download"
                        />
                      </Button>
                    </InputGroup.Append>
                  )}
                </InputGroup>
                {errors.numero_documento && (
                  <Form.Text className="text-danger">
                    {errors?.numero_documento?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="bg-soft-blue ">
            <Col lg md={6} sm={6}>
              <Form.Group
                as={Col}
                controlId="rut_factura"
                className="pr-0 pl-0 mb-1"
              >
                <Form.Label>RUT</Form.Label>
                <Form.Control
                  type="text"
                  name="rut_factura"
                  //defaultValue={getValues("rut_factura")}
                  ref={register}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg sm={6}>
              <Form.Group
                as={Col}
                controlId="razon_social_factura"
                className="pr-0 pl-0 mb-1"
              >
                <Form.Label>Nombre y Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="razon_social_factura"
                  //defaultValue={getValues("razon_social_factura")}
                  ref={register}
                  readOnly
                />
              </Form.Group>
            </Col>
            {/**validateButton() && (
              <Col md sm={12}>
                <Button
                  variant="warning"
                  type="button"
                  onClick={(event) => validateDocument(event)}
                  className="btn-sm mt-4"
                  disabled={loadingPago}
                  id="generar-documento"
                >
                  {loadingPago ? "Generando..." : "Generar Documento"}
                </Button>
              </Col>
            )**/}
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Pagos;
