import { toast } from "react-toastify";
import { useState } from "react";

import {
  searchCustomer,
  // habilitarNuevoCliente,
} from "actions/admin/customerAction";
import { validarRut } from "utils/rut";
import { useDispatch, useSelector } from "react-redux";
import { getPhone } from "utils/globals";
const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

export const useCustomerOrder = () => {
  const dispatch = useDispatch();
  const [dataCodigo, setDataCodigo] = useState("");
  const [codigo, setCodigo] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [habCliente, setHabCliente] = useState(true);
  const [habDestination, setHabDestination] = useState(true);
  const [habRemitente, setHabRemitente] = useState(true);
  const [typeCustomer, setTypeCustomer] = useState("principal");
  const [customer, setCustomer] = useState("principal");
  const [openCloseCta, setOpenCloseCta] = useState(false);
  const [habRazonSocial, setHabRazonSocial] = useState(true);

  const { companyDataBasic } = useSelector((state) => state.companies);

  const onCustomerBlur = async (event, setValue, getValues) => {
    if (dataCodigo.length > 0) {
      await searchClientePrincipal("codigo", setValue);
    } else if (event.target.value === "") {
      //setHidden(false);
      setValue("razon_social", "");
      setValue("cliente_existe", "creado");
      //setHabCliente(true);
      //setValue("cliente_rut", "")
      //dispatch(clienteCtaCorriente(false))
      if (getValues("tipo_cliente") === "remitente") {
        setValue("rut_remitente", "");
        setValue("razon_social_remitente", "");
        //setDisabledRemitente(false);
      } else {
        setValue("rut_destinatario", "");
        setValue("razon_social_destinatario", "");
        setValue("direccion_destinatario", "");
        setValue("telefono_avisas", "");
      }
      setValue("codigo_interno", "si");
      //setDataClientSearch(null);
      //setCodigo(false)
      //setValue("codigo_interno_nro", "")
    }
  };

  const activeInputAccountNumber = (event, setValue) => {
    /**if (event.target.value === "nuevo") {
      //dispatch(habilitarNuevoCliente(false));
      setHabCliente(false);
    } else {
      setHabCliente(true);
      //dispatch(habilitarNuevoCliente(true));
    }**/
    setHabCliente(true);
    setDataCodigo("");
    setValue("razon_social", "");
    setValue("cliente_rut", "");
    setCodigo(false);
    setValue("codigo_interno", "no");
    setValue("codigo_interno_nro", "");

    const nameTypeCustomer = document.querySelector(
      "input[type=radio][name=tipo_cliente]:checked"
    ).value;

    if (nameTypeCustomer === "remitente") {
      setValue("rut_remitente", "");
      setValue("razon_social_remitente", "");
      setHabRemitente(true);
    } else {
      setValue("rut_destinatario", "");
      setValue("razon_social_destinatario", "");
      setValue("direccion_destinatario", "");
      setValue("telefono_avisas", "");
      setHabDestination(true);
    }
  };

  const changeValueCI = (event, setValue) => {
    setDataCodigo("");
    setValue("codigo_interno", event.target.value);
    setCodigo(event.target.value === "no" ? false : true);
    cleanInput(setValue, null, "codigo", "");
    setHabCliente(true);
    setCustomer(null);

    if (event.target.value === "si") {
      changePayment(setValue);
    }

    const nameTypeCustomer = document.querySelector(
      "input[type=radio][name=tipo_cliente]:checked"
    ).value;

    if (nameTypeCustomer !== "remitente") {
      setValue("rut_remitente", "");
      setValue("razon_social_remitente", "");
      setHabRemitente(true);
    } else {
      setValue("rut_destinatario", "");
      setValue("razon_social_destinatario", "");
      setValue("direccion_destinatario", "");
      setValue("telefono_avisas", "");
      setHabDestination(true);
    }

    //dispatch(clienteCtaCorriente(false));
  };

  const onKeyDownHandlerInput = async (event, setValue, getValues) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      if (event.target.value.length > 0) {
        /**setValue("codigo_interno", "no");
        setCodigo(false);
        setDataCodigo("");
        setValue("codigo_interno_nro", "");
        setValue("razon_social", "");
        if (getValues("tipo_cliente") === "remitente") {
          setValue("rut_remitente", "");
          setValue("razon_social_remitente", "");
          //setDisabledRemitente(true);
        } else {
          setValue("rut_destinatario", "");
          setValue("razon_social_destinatario", "");
          setValue("direccion_destinatario", "");
          setValue("telefono_avisas", "");
          //setDisabledDestinatario(true);
        }**/

        await searchClientePrincipal("input", setValue);
      }
    } else {
      //handleBlurRut("");
    }
  };

  const onKeyDownHandlerAccountNummber = async (event, setValue, getValues) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (event.target.value.length > 0) {
        //setDataCodigo("")
        //setValue("codigo_interno_nro", "")
        await searchClientePrincipal("codigo", setValue);
        changePayment(setValue);
      }
    } else {
      /**setValue("codigo_interno", "si");
      setCodigo(true);
      setValue("cliente_rut", "");
      setValue("razon_social", "");
      if (getValues("tipo_cliente") === "remitente") {
        setValue("rut_remitente", "");
        setValue("razon_social_remitente", "");
        //setDisabledRemitente(true);
      } else {
        setValue("rut_destinatario", "");
        setValue("razon_social_destinatario", "");
        setValue("direccion_destinatario", "");
        setValue("telefono_avisas", "");
        ///setDisabledDestinatario(true);
      }**/
    }
  };

  const searchClientePrincipal = async (type, setValue) => {
    const result = await searchGlobalCustomer(
      type,
      setValue,
      document.getElementById("cliente_rut").value.toString().toUpperCase()
    );
    setCustomer(result?.data);
    setValue("forma_pago_id", "");
    if (result?.status) {
      setValue("cliente_existe", "creado");

      const nameTypeCustomer = document.querySelector(
        "input[type=radio][name=tipo_cliente]:checked"
      ).value;

      if (type === "codigo" || result?.data?.account_numbers?.length === 0) {
        cleanInput(
          setValue,
          result?.data,
          type,
          type === "input" ? "" : dataCodigo
        );

        if (nameTypeCustomer !== "remitente") {
          setHabDestination(false);
        }
        setHabCliente(true);
      } else if (result?.data?.account_numbers?.length === 1) {
        cleanInput(
          setValue,
          result?.data,
          type,
          result?.data?.account_numbers[0].internal_number
        );
        if (nameTypeCustomer !== "remitente") {
          setHabDestination(false);
        }
        setHabCliente(true);
        setValue("codigo_interno", "si");
        setCodigo(true);
        changePayment(setValue);
      } else if (result?.data?.account_numbers?.length > 1) {
        setOpenCloseCta(true);
        changePayment(setValue);
      }
    } else {
      if (type === "codigo" || !result?.created) {
        toast.error(result?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      } else {
        setTypeCustomer("principal");
        setHidden(true);
        setValue("cliente_existe", "nuevo");
      }
    }
  };

  const searchGlobalCustomer = async (type, setValue, rut) => {
    let search;

    if (type === "codigo") {
      search = { search_type: "codigo_interno", value: dataCodigo };
    } else {
      const isValid = validarRut(rut);
      if (isValid.status) {
        search = { search_type: "rut_cliente", value: rut };
      } else {
        toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
        return false;
      }
    }

    const result = await dispatch(searchCustomer(search));
    return result;
  };

  const cleanInput = (setValue, data, type, code) => {
    setValue("razon_social", data?.full_name ?? "");
    setValue("cliente_rut", data?.rut ?? "");
    setValue("cliente_rut", data?.rut ?? "");

    if (type === "input") {
      setCodigo(type === "input" ? false : true);
      setValue("codigo_interno", type === "input" ? "no" : "si");
    }

    setValue("codigo_interno_nro", code);

    const nameTypeCustomer = document.querySelector(
      "input[type=radio][name=tipo_cliente]:checked"
    ).value;

    if (nameTypeCustomer === "remitente") {
      setValue("rut_remitente", data?.rut ?? "");
      setValue("razon_social_remitente", data?.full_name ?? "");
    } else {
      setValue("rut_destinatario", data?.rut ?? "");
      setValue("razon_social_destinatario", data?.full_name ?? "");
      setValue("direccion_destinatario", data?.address ?? "");
      setValue(
        "telefono_avisas",
        getPhone(data?.phone_number, companyDataBasic)
      );
      //setHabDestination(false);
    }
  };

  const searchCustomerRemitente = async (type, setValue, rut) => {
    const result = await searchGlobalCustomer(type, setValue, rut);

    if (result?.status) {
      if (type === "remitente") {
        setValue(
          "razon_social_remitente",
          rut === RUT_MESON ? "" : result?.data?.full_name
        );
        setHabRemitente(rut === RUT_MESON ? false : true);
        setHabRazonSocial(true);
      } else {
        setValue(
          "razon_social_destinatario",
          rut === RUT_MESON ? "" : result?.data?.full_name
        );
        setValue("direccion_destinatario", result?.data.address);
        setValue(
          "telefono_avisas",
          getPhone(result?.data.phone_number, companyDataBasic)
        );
        setHabDestination(false);
        setHabRazonSocial(true);
      }
    } else {
      if (!result?.created) {
        toast.error(result?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      } else {
        setTypeCustomer(type);
        setHidden(true);
      }
    }
  };

  const changeTypeCliente = (event, setValue, getValues) => {
    const type_cliente = event.target.value;

    setValue("rut_remitente", "");
    setValue("razon_social_remitente", "");

    setValue("direccion_destinatario", "");
    setValue("telefono_avisas", "");
    setHabRemitente(true);
    setHabDestination(type_cliente === "destinatario" ? false : true);
    dataTypeCliente(type_cliente, "", setValue);
    setValue(
      "rut_destinatario",
      type_cliente === "destinatario" ? getValues("cliente_rut") : ""
    );
    setValue(
      "razon_social_destinatario",
      type_cliente === "destinatario" ? getValues("razon_social") : ""
    );
  };

  const handleBlurRut = (event, setValue, getValues) => {
    if (event === "") {
      setHidden(false);
      setValue("razon_social", "");
      setValue("cliente_existe", "creado");
      setHabCliente(true);
      //setValue("cliente_rut", "")
      //dispatch(clienteCtaCorriente(false))
      if (getValues("tipo_cliente") === "remitente") {
        setValue("rut_remitente", "");
        setValue("razon_social_remitente", "");
        setHabRemitente(true);
      } else {
        setValue("rut_destinatario", "");
        setValue("razon_social_destinatario", "");
        setValue("direccion_destinatario", "");
        setValue("telefono_avisas", "");
      }
      setValue("codigo_interno", "no");
      setCodigo(false);
      setValue("codigo_interno_nro", "");
      setCustomer(null);
    }
  };

  const dataTypeCliente = (type_cliente, direccion, setValue) => {
    const name_cliente = document.getElementById("razon_social").value;
    const cliente_rut = document.getElementById("cliente_rut").value;

    if (type_cliente === "remitente") {
      setValue("rut_remitente", cliente_rut);
      if (cliente_rut === RUT_MESON) {
        setValue("razon_social_remitente", "");
        //setDisabledRemitente(false);
      } else {
        setValue("razon_social_remitente", name_cliente);
        //setDisabledRemitente(true);
      }
    } else if (type_cliente === "destinatario") {
      setValue("rut_destinatario", cliente_rut);
      setValue(
        "razon_social_destinatario",
        cliente_rut === RUT_MESON ? "" : customer?.full_name ?? ""
      );
      setValue(
        "direccion_destinatario",
        cliente_rut === RUT_MESON ? "" : customer?.address ?? ""
      );
      setValue(
        "telefono_avisas",
        getPhone(customer?.phone_number, companyDataBasic) ?? ""
      );
    }
  };

  const handleOpenCloseCta = () => {
    setOpenCloseCta(!openCloseCta);
  };

  const handleBlurCliente = (event, setValue, getValues) => {
    if (document.getElementById("cliente_rut").value.length > 0) {
      const nameTypeCustomer = document.querySelector(
        "input[type=radio][name=tipo_cliente]:checked"
      ).value;
      if (nameTypeCustomer === "remitente") {
        dataTypeCliente(
          document.getElementById("tipo_cliente_remitente").value,
          "",
          setValue
        );
      } else {
        dataTypeCliente(
          document.getElementById("tipo_cliente_destinatario").value,
          "",
          setValue
        );
        setValue("razon_social_destinatario", getValues("razon_social") ?? "");
      }
    }
  };

  const changePayment = (setValue) => {
    setValue("documentacion_id_pago", "");
    setValue("numero_documento", "");
    setValue("forma_pago_id", "");
  };

  const state = {
    dataCodigo,
    codigo,
    hidden,
    habCliente,
    habDestination,
    habRemitente,
    typeCustomer,
    customer,
    openCloseCta,
    habRazonSocial,
  };

  const actions = {
    onCustomerBlur,
    activeInputAccountNumber,
    setDataCodigo,
    changeValueCI,
    setCodigo,
    onKeyDownHandlerInput,
    onKeyDownHandlerAccountNummber,
    setHidden,
    cleanInput,
    setHabCliente,
    searchCustomerRemitente,
    setHabRemitente,
    setHabDestination,
    changeTypeCliente,
    dataTypeCliente,
    handleBlurRut,
    handleOpenCloseCta,
    handleBlurCliente,
    setHabRazonSocial,
  };

  return {
    state,
    actions,
  };
};
